:root {
  padding: 0;
  margin: 0;

  /* globals */
  --bg-color: #efe3cb;
  --accent-color: rgb(45, 40, 104);
  --link-color: rgb(35, 130, 104);
  --text-size-mult: 1.2;
  --element-margin: 1em;
  --text-size-title: calc(24px * var(--text-size-mult));
  --text-size-button: calc(16px * var(--text-size-mult));
  --text-size-paragraph: calc(16px * var(--text-size-mult));

  /* debug */
  --border-DEBUG: 1px solid red;
}

html {
  /* background-color: rgb(241, 253, 255); */
  text-align: center;
  /* color: white; */
  background-color: var(--bg-color);
  color: var(--accent-color);
  /* font: 12px "Helvetica Neue", Helvetica, Arial; */
  /* font-family: "Lucida Console", "Courier New", monospace; */
}

h3 {
  padding: 0.4em;
  margin: 0;
  padding-left: 0;
}

body {
  padding: 0;
  margin: 0;
  /* font-family: "Monospace", "Monaco"; */
}

ul {
  margin: 0;
}

.About {
  /* border-bottom: 1px solid black; */
  padding: 0.4em;
  padding-left: 0;
  text-align: left;
}

.Layout {
  margin: var(--element-margin);
  margin-top: 0;
  position: absolute;
  width: calc(100% - var(--element-margin) * 2);
}

.Content-container {
  /* position: relative; */
}

.Content {
  /* width: calc(100% - 0.4em * 2); */
  height: 100%;
  padding-top: 0.4em;
  padding-bottom: 56px;
  font-size: var(--text-size-paragraph);
}

.Content a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: 600;
}

.Content a:hover {
  text-decoration: underline;
}

.Content a:visited {
  color: var(--link-color);
}

.Gallery-img {
  border-radius: 20px;
  /* border: 1px solid black; */
  margin: 0.2em;
  width: calc(100% - var(--element-margin));
  max-width: 500px;
}

.Header {
  background-color: var(--bg-color);
  position: sticky;
  padding-top: var(--element-margin);
  top: 0;
  border-bottom: 1px solid black;
  /* position: relative; */
}

.Header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.4em;
}

.Header-item {
  font-weight: bold;
  flex: 1;
  font-size: var(--text-size-button);
}

.Header-item-button {
  flex: 100;
  font-size: var(--text-size-button);
}
.Header-item-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Header-item-title {
  flex: 1;
  font-size: var(--text-size-title);
}
.Header-item-title:hover {
  cursor: pointer;
}

.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 42px;
  border-top: 1px solid black;
  background-color: var(--accent-color);
  color: #efe3cb;
  text-align: center;
  z-index: 10;
}

.Footer-container {
  display: flex;
  align-items: left;
  justify-content: left;
}

.Footer-item {
  padding: 8px;
  flex: 1;
}

.Footer-item:hover {
  cursor: pointer;
}

.Socials-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Socials-item {
  padding: 8px;
}

.Socials-item:hover {
  /* cursor: pointer; */
}

.Button-disabled {
  color: gray;
}

.Button-disabled:hover {
  text-decoration: none;
  cursor: default;
}
